// @ts-check

/**
 * @typedef {{
 *  allowedUserRoles?: ('admin'|'member'|'readonly')[],
 *  allowedPermissions?: string[],
 *  public?: boolean,
 * }} EtRouteMetaModel
 */

/**
 * @typedef {{
 * path: string,
 * name: string,
 * component?: () => Promise<typeof import('*.vue')>,
 * meta?: EtRouteMetaModel,
 * redirect?: { name: string },
 * }} EtRouteModel
 */

/**
 * @typedef {{ children?: EtRouteModel[] }} EtChildrenModel
 */

/**
 * @typedef {EtRouteModel & EtChildrenModel} EtRouteModelWithChildren
 */

// TODO: Maybe put the account/create outside of any clause on permissions (check if it works with a logged in user - no member first)
/**
 * @type {EtRouteModelWithChildren[]}
 */
export const privateRoutes = [
  {
    path: '/lightadvertiser',
    name: 'POCLightAdvertiser',
    component: () => import('@/views/pocLightAdvertiser/LightAdvertiser.vue'),
  },
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/home/HomePage.vue'),
  },
  {
    path: '/favorites',
    name: 'FavoritesPage',
    component: () => import('@/views/favorites/FavoritesPage.vue'),
  },
  {
    path: '/searchagents',
    name: 'SearchAgentsPage',
    component: () => import('@/views/searchAgents/SearchAgentsPage.vue'),
  },
  {
    path: '/listings',
    name: 'ListingsIndex',
    component: () => import('@/views/listings/ListingsIndex.vue'),
    meta: {
      allowedUserRoles: ['admin', 'member', 'readonly'],
    },
  },
  {
    path: '/listings/create',
    name: 'ListingsCreate',
    component: () => import('@/views/listings/ListingsCreate.vue'),
    meta: {
      allowedUserRoles: ['admin', 'member'],
    },
  },
  {
    path: '/listings/rental/',
    name: 'RentalListingsIndex',
    component: () => import('@/views/RentalListing/RentalListingsIndex.vue'),
    meta: {
      allowedUserRoles: ['admin', 'member', 'readonly'],
      allowedPermissions: ['rentalListing'],
    },
    children: [
      {
        path: ':id',
        name: 'RentalListingsShow',
        component: () => import('@/views/RentalListing/RentalListingsShow.vue'),
      },
      {
        path: ':id/update',
        name: 'RentalListingsUpdate',
        component: () => import('@/views/RentalListing/RentalListingForm.vue'),
        meta: {
          allowedUserRoles: ['admin', 'member'],
        },
      },
      {
        path: 'create',
        name: 'RentalListingsCreate',
        component: () => import('@/views/RentalListing/RentalListingForm.vue'),
        meta: {
          allowedUserRoles: ['admin', 'member'],
        },
      },
      {
        path: ':id/close',
        name: 'RentalListingsClose',
        component: () => import('@/views/RentalListing/RentalListingsClose.vue'),
        meta: {
          allowedUserRoles: ['admin', 'member'],
        },
      },
    ],
  },
  {
    path: '/listings/investment/',
    name: 'InvestmentListingsIndex',
    component: () => import('@/views/InvestmentListing/InvestmentListingsIndex.vue'),
    meta: {
      allowedUserRoles: ['admin', 'member', 'readonly'],
      allowedPermissions: ['investmentListing'],
    },
    children: [
      {
        path: ':id',
        name: 'InvestmentListingsShow',
        component: () => import('@/views/InvestmentListing/InvestmentListingsShow.vue'),
      },
      {
        path: ':id/update',
        name: 'InvestmentListingsUpdate',
        component: () => import('@/views/InvestmentListing/InvestmentListingForm.vue'),
        meta: {
          allowedUserRoles: ['admin', 'member'],
        },
      },
      {
        path: 'create',
        name: 'InvestmentListingsCreate',
        component: () => import('@/views/InvestmentListing/InvestmentListingForm.vue'),
        meta: {
          allowedUserRoles: ['admin', 'member'],
        },
      },
      {
        path: ':id/close',
        name: 'InvestmentListingsClose',
        component: () => import('@/views/InvestmentListing/InvestmentListingsClose.vue'),
        meta: {
          allowedUserRoles: ['admin', 'member'],
        },
      },
    ],
  },
  {
    path: '/listings/sales/',
    name: 'SalesListingsIndex',
    component: () => import('@/views/SalesListing/SalesListingsIndex.vue'),
    meta: {
      allowedUserRoles: ['admin', 'member', 'readonly'],
      allowedPermissions: ['salesListing'],
    },
    children: [
      {
        path: ':id',
        name: 'SalesListingsShow',
        component: () => import('@/views/SalesListing/SalesListingsShow.vue'),
      },
      {
        path: ':id/update',
        name: 'SalesListingsUpdate',
        component: () => import('@/views/SalesListing/SalesListingForm.vue'),
        meta: {
          allowedUserRoles: ['admin', 'member'],
        },
      },
      {
        path: 'create',
        name: 'SalesListingsCreate',
        component: () => import('@/views/SalesListing/SalesListingForm.vue'),
        meta: {
          allowedUserRoles: ['admin', 'member'],
        },
      },
      {
        path: ':id/close',
        name: 'SalesListingsClose',
        component: () => import('@/views/SalesListing/SalesListingsClose.vue'),
        meta: {
          allowedUserRoles: ['admin', 'member'],
        },
      },
    ],
  },
  {
    path: '/listings/officespace/',
    name: 'OfficespaceListingsIndex',
    component: () => import('@/views/officespaceListing/OfficespaceListingsIndex.vue'),
    meta: {
      allowedUserRoles: ['admin', 'member', 'readonly'],
      allowedPermissions: ['officeSpaceListing'],
    },
    children: [
      {
        path: ':id',
        name: 'OfficespaceListingsShow',
        component: () => import('@/views/officespaceListing/OfficespaceListingsShow.vue'),
      },
      {
        path: ':id/update',
        name: 'OfficespaceListingsUpdate',
        component: () => import('@/views/officespaceListing/OfficespaceListingForm.vue'),
        meta: {
          allowedUserRoles: ['admin', 'member'],
        },
      },
      {
        path: 'create',
        name: 'OfficespaceListingsCreate',
        component: () => import('@/views/officespaceListing/OfficespaceListingForm.vue'),
        meta: {
          allowedUserRoles: ['admin', 'member'],
        },
      },
      {
        path: ':id/close',
        name: 'OfficespaceListingsClose',
        component: () => import('@/views/officespaceListing/OfficespaceListingsClose.vue'),
        meta: {
          allowedUserRoles: ['admin', 'member'],
        },
      },
    ],
  },
  {
    path: '/selectaccount',
    name: 'SelectAccountIndex',
    component: () => import('@/views/account/SelectAccountIndex.vue'),
    meta: {
      allowedUserRoles: ['admin', 'member', 'readonly'],
    },
  },
  {
    path: '/account/create', // TODO: What is this????
    name: 'AccountCreate',
    component: () => import('@/views/account/AccountCreate.vue'),
  },
  {
    path: '/account',
    name: 'AccountIndex',
    redirect: { name: 'AccountShow' },
    meta: {
      allowedUserRoles: ['admin', 'member', 'readonly'],
    },
    children: [
      {
        path: '',
        name: 'AccountShow',
        component: () => import('@/views/account/AccountShow.vue'),
      },
      // {
      //   path: 'create',
      //   name: 'AccountCreate',
      //   component: () => import('@/views/account/AccountCreate.vue'),
      // },
      {
        path: 'update',
        name: 'AccountUpdate',
        component: () => import('@/views/account/AccountForm.vue'),
        meta: {
          allowedUserRoles: ['admin'],
          allowedPermissions: ['accountSettings'],
        },
      },
      {
        path: 'statistics',
        name: 'AccountStatistics',
        component: () => import('@/views/account/AccountStatistics.vue'),
        meta: {
          allowedPermissions: ['accountStatistics'],
        },
      },
    ],
  },
  {
    path: '/users/:id', // TODO: What is this????
    name: 'UsersShow',
    component: () => import('@/views/user/UsersShow.vue'),
    meta: {
      allowedUserRoles: ['admin', 'member', 'readonly'],
    },
  },
  {
    path: '/user',
    name: 'UserProfileIndex',
    component: () => import('@/views/user/UserProfileIndex.vue'),
  },
  {
    path: '/manuals',
    name: 'ManualsIndex',
    component: () => import('@/views/manuals/ManualsIndex.vue'),
    meta: {
      allowedUserRoles: ['admin', 'member'],
    },
    children: [
      {
        path: '',
        name: 'ManualsListPage',
        component: () => import('@/views/manuals/ManualsView.vue'),
      },
      {
        path: ':slug',
        name: 'ManualsPage',
        component: () => import('@/views/manuals/DisplayManualView.vue'),
      },
    ],
  },
];

/**
 * Extracts names from routes and children to object of { name: name, name2: name2, ...}
 */
export const privateRouteNames = privateRoutes
  .flatMap(r => [r.name, ...(r.children ? r.children.map(rr => rr.name) : [null])].filter(f => f))
  .reduce((a, v) => ({ ...a, [v]: v }), {});
