<template>
  <EtNavbar>
    <template #title="{ closeMenu }">
      <RouterLink
        :to="{ name: routeNames.HomePage }"
        @click="closeMenu">
        {{ title }}
      </RouterLink>
    </template>
    <template #nav1items>
      <EtNavbarItem>
        <a
          :href="wwwLink"
          target="_blank">
          {{ t('layout.theNavigationBar.gotoEt') }}
        </a>
      </EtNavbarItem>
    </template>

    <template #nav2items="{ closeMenu }">
      <EtNavbarItem
        v-if="userStore.isAuthenticated && userStore.hasUserRole('admin', 'member')"
        icon="add">
        <RouterLink
          :to="{ name: routeNames.ListingsCreate }"
          @click="closeMenu">
          {{ t('layout.theNavigationBar.newListing') }}
        </RouterLink>
      </EtNavbarItem>
      <EtNavbarItem icon="user">
        <a
          v-if="!userStore.isAuthenticated"
          @click="onLogin">
          {{ t('layout.theNavigationBar.login') }}
        </a>
        <RouterLink
          v-else
          :to="{ name: routeNames.UserProfileIndex }"
          @click="closeMenu">
          {{ t('layout.theNavigationBar.loggedOnAs', { email: userStore.user?.email }) }}
        </RouterLink>
      </EtNavbarItem>
    </template>

    <template
      v-if="userStore.isAuthenticated"
      #nav3items>
      <RouterLink
        v-for="child in computedLinks"
        v-slot="{ href, navigate, isExactActive }"
        :key="child.label"
        :to="{ name: child.routeName }"
        custom>
        <EtNavbarItem
          :is-active="isExactActive"
          :href="href"
          @click="navigate">
          {{ child.label }}
        </EtNavbarItem>
      </RouterLink>
      <div
        v-if="!userStore.activeAccount"
        class="u-ml-a">
        <EtNavbarItem
          href="/account/create">
          Opret konto
        </EtNavbarItem>
      </div>
      <div
        v-if="userStore.activeAccount"
        class="u-ml-a">
        {{ userStore.activeAccount.name }}
      </div>
    </template>
  </EtNavbar>
</template>

<script setup>
  // @ts-check
  import { EtNavbar, EtNavbarItem } from 'vue-ui-kit';
  import { useAuth } from '@common/composables/auth/authPlugin.js';
  import { RouterLink } from 'vue-router';
  import { routeNames } from '@/router';
  import { useT } from '@common/i18n/i18n.js';
  import { useUserStore } from '@common/store/index.js';
  import { computed } from 'vue';

  const t = useT();
  const userStore = useUserStore();
  const { login } = useAuth();

  const onLogin = () => {
    login({ fullPath: '/' });
  };

  const wwwLink = import.meta.env.VITE_PATH_WWW;

  /**
   * @type {{ label: string, routeName: string, allowedUserRoles?: ('admin'|'member'|'readonly')[], allowedPermissions?: string[], allowedAccountType?: number }[]}
   */
  const links = [{
    label: t('layout.theNavigationBar.listings', 2),
    routeName: routeNames.ListingsIndex,
    allowedUserRoles: ['admin', 'member', 'readonly'],
  }, {
    label: t('layout.theNavigationBar.account'),
    routeName: routeNames.AccountShow,
    allowedUserRoles: ['admin', 'member', 'readonly'],
  }, {
    label: t('layout.theNavigationBar.accountStatistics'),
    routeName: routeNames.AccountStatistics,
    allowedUserRoles: ['admin', 'member', 'readonly'],
    allowedPermissions: ['accountStatistics'],
  }, {
    label: t('layout.theNavigationBar.favorites'),
    routeName: routeNames.FavoritesPage,
  }, {
    label: t('layout.theNavigationBar.searchAgents'),
    routeName: routeNames.SearchAgentsPage,
  }, {
    label: 'Manualer',
    routeName: routeNames.ManualsListPage,
    allowedUserRoles: ['admin', 'member', 'readonly'],
    // allowedPermissions: ['manuals'],
    // allowedAccountType: 1,
  }, {
    label: 'POC Reepay dialog',
    routeName: routeNames.POCLightAdvertiser,
    allowedUserRoles: ['admin', 'member', 'readonly'],
    allowedPermissions: ['accountStatistics'],
    allowedAccountType: 2, // 'lightAdvertiser',
  }];

  const title = computed(() => {
    if (userStore.activeAccount) {
      return t(`layout.theNavigationBar.titles.${userStore.activeAccount.accountType}`);
    }

    return t('layout.theNavigationBar.titles.user');
  });

  const computedLinks = computed(() => {
    return links.filter((link) => {
      if (!link.allowedPermissions && !link.allowedUserRoles && !link.allowedAccountType) {
        return true;
      }

      if (userStore.accounts.length === 0) {
        return false;
      }

      let allowed = false;
      if (link.allowedUserRoles) {
        allowed = link.allowedUserRoles.some(role => userStore.hasUserRole(role));
      }

      if (allowed && link.allowedPermissions) {
        allowed = link.allowedPermissions.some(permission => userStore.hasPermission(permission));
      }

      if (allowed && link.allowedAccountType) {
        allowed = userStore.activeAccount?.accountType === link.allowedAccountType;
      }

      return allowed;
    });
  });
</script>
